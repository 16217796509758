import Vue from 'vue';

export default Vue.observable({
    activeTrailer: null,
    previewPos: {},
    interface: null,
    isOpened: false,
    isClosed: true,
    open: false,
    preview: false
});
