<template src="../templates/simple-overview.html"></template>

<script>
    import FigureAction from "./figureAction";
    import AnimText from "./animText"

    export default {
        components: {FigureAction, AnimText},
        props: {
            data: Object
        },
        data() {
            return {
                index: 1
            }
        },
        computed: {
            hasButtonSlot() {
                return !!this.$slots.button
            },
            titleMobile() {
                if (!this.$device.mobile)
                    return ''

                return this.data.title_mobile
            }
        },
        methods: {}
    }
</script>
