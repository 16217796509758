<template>
    <div class="fg-action"><span class="fg-action__icon" :class="{'--has-label': label}" @click="onClick"></span>{{label}}
    </div>
</template>

<script>
    export default {
        name: "figureAction",
        props: ['label'],
        methods: {
            onClick() {
                this.$emit('click')
            }
        }
    }
</script>

<style scoped>

</style>