<template>

    <div v-show="preview" class="section is-home">
        <div class="bottom-background"></div>
        <div class="section__body">
            <video-overview key="'preview'" :data="preview" v-if="preview">
                <template v-slot:button>
                    <router-link class="button overview__btn-play"
                                 :class="{'overview__btn-light':mainReady}"
                                 :to="{name:route.WATCH_MAIN}"><span class="btn-bg"></span><span>{{
                            labels?.home.button || text.home.button
                        }}</span>
                    </router-link>
                </template>
            </video-overview>
        </div>
        <div class="section__footer">
            <h2 v-if="trailers.length" class="overview__heading">{{
                    labels?.home.trailers || text.home.trailers
                }}</h2>
            <div class="section__row">
                <transition name="opacity" mode="out-in">
                    <the-carousel v-if="trailers.length" :items="trailers" @sliderMove="sliderMove"
                                  @transitionStart="sliderMove" @transitionEnd="sliderMoved"
                    >
                        <template v-slot:default="{item}">
                            <div @click="onTrailerClick(item)"
                                 @mouseover="onTrailerHover($event, item)"
                                 @mouseleave.self="onTrailerLeave">
                                <thumb-trailer :item="item"/>
                            </div>
                        </template>
                    </the-carousel>
                </transition>
            </div>
        </div>
    </div>

</template>

<script>
import {find as _fine} from "lodash";
import store from "../store";
import storeTrailer from "../storeTrailer";
import eventBus from "../eventBus";
import VideoOverview from "../components/videoOverview";
import text from "../text.json"
import routeMixin from "../mixins/routeMixin";
import TheCarousel from "../components/TheCarousel.vue";
import ThumbTrailer from "../components/ThumbTrailer.vue";
// import TrailerModal from "../components/TrailerModal.vue";
import requestMixin from "../mixins/requestMixin";

export default {
    name: "Home",
    components: {VideoOverview, TheCarousel, ThumbTrailer,},
    mixins: [routeMixin, requestMixin],
    data() {
        return {
            autoPlay: null,
            autoplayTimeout: 2000,
            btnWidth: 0,
            btnAnimate: null,
            activeTrailer: null,
            openModal: false,
            previewModal: false,
            previewPos: {}
        }
    },
    computed: {
        preview() {
            return store.preview
        },
        project() {
            return store.project
        },
        projects() {
            return store.projects
        },
        mainReady() {
            return store.video && Object.keys(store.video).length
        },
        labels() {
            return store.labels
        },
        text() {
            return text
        },
        trailers() {
            return store.trailers
        },
        interface: () => {
            return storeTrailer.interface
        }
    },

    watch: {
        mainReady: {
            handler: function (val) {
                if (val) {
                    this.btnAnimate = true
                }
            },
            immediate: true
        },

        projects: {
            handler: function (val) {
                if (val.length) {
                    this.setProject()
                }
            },
            immediate: true
        },


    },

    deactivated() {
        clearTimeout(this.autoPlay)
    },

    mounted() {
        if (!store.trailers || !store.trailers.length) {
            this.loadTrailers()
        }

        eventBus.$on("modalOpen", () => {
            this.onModalOpen()
        })
        eventBus.$on("modalClose", () => {
            this.onModalClose()
        })
    }
    ,

    methods: {
        playVideo() {
            if (store.video && Object.keys(store.video).length) {
                if (this.autoPlay) {
                    return;
                }

                this.autoPlay = setTimeout(() => {
                    this.$router.push({name: 'WatchMain'})
                }, this.autoplayTimeout)
                this.$nextTick(() => {
                    this.btnWidth = 100
                })
            }
        }
        ,

        animBtn() {
            this.btnAnimate = true
        },

        setProject() {
            store.project = store.projects[Math.floor(Math.random() * store.projects.length)];
        },

        getTrailerModalInterface(modalInterface) {
            this.$options.modalInterface = modalInterface
        },

        onTrailerClick(payload) {
            if (this.openModal) {
                return
            }

            this.openModal = true
            storeTrailer.open = true

            if (!this.activeTrailer || this.activeTrailer && this.activeTrailer.id !== payload.id) {
                this.activeTrailer = payload
                storeTrailer.activeTrailer = payload
            }

            eventBus.$emit('onTrailerClick', {activeTrailer: this.activeTrailer, previewPos: this.previewPos})

            // this.$nextTick(() => {
            //     this.$options.modalInterface.showPopup()
            // })
        }
        ,

        onTrailerHover(event, payload) {
            if (this.openModal) {
                return
            }

            let target = event.target.closest('.swiper-slide.swiper-slide-visible')
            if (!target) {
                return;
            }
            this.previewModal = true
            this.activeTrailer = payload
            this.previewPos = {
                x: target.getBoundingClientRect().x,
                y: target.getBoundingClientRect().y,
                width: target.getBoundingClientRect().width,
                height: target.getBoundingClientRect().height,
            }
            storeTrailer.activeTrailer = this.activeTrailer
            storeTrailer.previewPos = this.previewPos
            storeTrailer.preview = this.previewModal

            eventBus.$emit('onTrailerHover', {activeTrailer: this.activeTrailer, previewPos: this.previewPos})
            // this.$nextTick(() => {
            //     this.$options.modalInterface.showPreview();
            // })
        }
        ,

        onTrailerLeave(payload, event) {
            this.previewModal = false
            if (this.openModal) {
                return
            }

            storeTrailer.preview = false
            // this.$options.modalInterface.closePreview()

            eventBus.$emit("onTrailerLeave")
        }
        ,

        onModalClose() {
            this.openModal = false
            this.activeTrailer = null
        }
        ,

        onModalOpen() {
            this.openModal = true
        }
        ,

        sliderMove() {
            this.onTrailerLeave()
            this.openModal = true
        }
        ,
        sliderMoved() {
            this.openModal = false
        }
        ,
    }
    ,

    modalInterface: {
        showPopup: () => {
        },
        closePopup:
            () => {
            },
        showPreview:
            () => {
            },
        closePreview:
            () => {
            }
    }
}
</script>

<style scoped lang="scss">
.bottom-background {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 100%);
    height: 435px;
}

.section {
    padding-bottom: 0;
    flex-direction: row;

    @media screen and (max-width: 500px) {
        align-content: flex-end;
    }
}

.project-logo {
    width: 55px;
    margin-left: 30px;
}

.section__body {
    padding-bottom: 0;
    position: relative;
    z-index: 2;
}

.section__footer {
    margin-top: auto;
    padding-bottom: 0;
    overflow: visible;
    min-height: 180px + 43px;
    position: relative;
    z-index: 1;

    @media (max-width: 767.98px) {
        margin-bottom: 0;
    }

    @media (max-width: 575.5px) {
        min-height: 50vw;
    }
}

.section__row {
    margin-top: -90px;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    position: relative;

    @media screen and (min-width: 767.5px) {
        margin-left: -3.819vw;
        margin-right: -3.819vw;

        width: calc(100% + 7.638vw);
    }

    @media (max-width: 575.5px) {
        margin-top: 21px;
    }

    //&::before {
    //    content: '';
    //    position: absolute;
    //    bottom: 0;
    //    left: -5px;
    //    right: -5px;
    //    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 100%);
    //    height: 435px;
    //    z-index: -1;
    //}
}

.overview__heading {
    margin-bottom: 10px;
    position: relative;
    z-index: 11;
    pointer-events: none;

    @media (max-width: 575.5px) {
        display: none;
    }
}

.overview__content {
    @media (max-width: 350.5px) {
        margin-bottom: 0;
    }
}

.swiper-carousel::v-deep {
    .swiper {
        padding: 0 45px;

        //@media (max-width: 767.98px) {
        //    padding: 0 15px;
        //}

        @media screen and (min-width: 1400px) {
            padding-left: 3.8194444%;
            padding-right: 3.8194444%;
        }

        @media (max-width: 575.5px) {
            padding: 0 24vw 0 41px;
        }
    }

    .swiper-wrapper {
        padding: 100px 0 13px;

        @media (max-width: 575.5px) {
            padding-top: 13px;
        }
    }

    .swiper-slide {
        width: 275px;

        &:hover {
            z-index: 25;
        }
    }

    .swiper-button-next {
        right: 15px;
        top: auto;
        bottom: 70px;

        @media (max-width: 575.5px) {
            right: 10px;
            bottom: 50%;
            margin-bottom: calc(var(--swiper-navigation-size) * 0.5 * -1);
        }
    }

    .swiper-button-prev {
        left: 15px;
        top: auto;
        bottom: 70px;

        @media (max-width: 575.5px) {
            left: 10px;
            bottom: 50%;
            margin-bottom: calc(var(--swiper-navigation-size) * 0.5 * -1);
        }
    }
}

.swiper-carousel.is-hovered::v-deep {
    .swiper {
        z-index: 15;
    }
}
</style>