<template>
    <div class="slide-text-container">
        <slot></slot>
        <span class="splash-text" :class="{ active: isAnimated }">&nbsp;&colon;&nbsp;</span>
        <transition name="slide-text" mode="out-in" @after-enter="lightText" >
            <span v-bind:key="index" class="slide-text">
                {{ text }}
            </span>
        </transition>
    </div>

</template>

<script>

    export default {
        name: "animText",
        props: {list: {type: Array, default: () => []}, time: {type: Number, default: 3000}},
        data() {
            return {
                text: '',
                index: 0,
                runInterval: null,
                isAnimated: false
            }
        },
        mounted() {
            if (this.list && this.list.length) {
                this.text = this.list[0]
                this.updateText()
            }
        },
        unmounted() {
            clearInterval(this.runInterval)
        },
        methods: {
            updateText() {
                this.runInterval = setInterval(() => {
                    this.index++
                    if (this.index >= this.list.length) {
                        this.index = 0
                    }
                    this.text = this.list[this.index]
                }, this.time)
            },

            lightText() {
                this.isAnimated = true;
                setTimeout(()=>{
                    this.isAnimated = false
                }, this.time*0.1)
            }
        }
    }
</script>

<style scoped>
    .splash-text {

    }
</style>
