<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-nav-right">
        <svg :width="w" :height="h" viewBox="0 0 32 32">
            <path d="M23.0612 17.0612L13.0612 27.0612C12.7794 27.343 12.3972 27.5013 11.9987 27.5013C11.6002 27.5013 11.218 27.343 10.9362 27.0612C10.6544 26.7794 10.4961 26.3972 10.4961 25.9987C10.4961 25.6002 10.6544 25.218 10.9362 24.9362L19.8749 15.9999L10.9387 7.0612C10.7992 6.92167 10.6885 6.75602 10.613 6.57372C10.5375 6.39141 10.4986 6.19602 10.4986 5.9987C10.4986 5.80137 10.5375 5.60598 10.613 5.42367C10.6885 5.24137 10.7992 5.07573 10.9387 4.9362C11.0782 4.79667 11.2439 4.68599 11.4262 4.61047C11.6085 4.53496 11.8039 4.49609 12.0012 4.49609C12.1985 4.49609 12.3939 4.53496 12.5762 4.61047C12.7585 4.68599 12.9242 4.79667 13.0637 4.9362L23.0637 14.9362C23.2034 15.0757 23.3141 15.2414 23.3896 15.4239C23.4651 15.6063 23.5039 15.8018 23.5036 15.9992C23.5034 16.1966 23.4642 16.3921 23.3883 16.5743C23.3123 16.7565 23.2012 16.922 23.0612 17.0612Z"/>
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-nav-right",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>