<template>
    <div class="thumb">
        <div class="thumb__media">
            <img v-if="item && item.thumbnail" :src="item.thumbnail.sizes?.miniature"/>
        </div>
        <h3 v-if="item && item.title" class="thumb__title" v-show="true">
            <icon-play-circle/>
            {{  item.title?.rendered ? item.title.rendered : item.title }}</h3>
    </div>
</template>

<script>
import IconPlayCircle from "../icons/iconPlayCircle.vue";

export default {
    name: "ThumbTrailer",
    components:{IconPlayCircle},
    props: {item: Object},
    data() {
        return {
            hover: false
        }
    },

    methods: {}
}
</script>


<style scoped>

</style>