<script>
// Import Swiper Vue.js components
import Swiper, {Navigation} from "swiper";
import IconNavLeft from "../icons/iconNavLeft.vue";
import IconNavRight from "../icons/iconNavRight.vue";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';

export default {
    name: "TheCarousel",
    components: {IconNavLeft, IconNavRight},
    props: {
        items: Array
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            // configure Swiper to use modules
            modules: [Navigation],
            // Optional parameters
            loop: false,
            spaceBetween: 12,
            watchSlidesProgress: true,
            slidesPerView: 1, //"auto",
            breakpoints: {
                500: {
                    slidesPerView: 2,
                },
                800: {
                    slidesPerView: 3,
                },
                1100: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 5,
                },
            },

            on: {
                sliderMove: (swiper, event) => {
                    this.$emit('sliderMove')
                },
                transitionStart: (swiper) => {
                    this.$emit('transitionStart')
                },
                transitionEnd: (swiper) => {
                    this.$emit('transitionEnd')
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        })
    },
    methods: {
        play(trailer) {
            this.$emit('click', {
                video: trailer
            })
        }
    }
};
</script>

<template>
    <div class="swiper-carousel">
        <!-- Slider main container -->
        <div ref="swiper" class="swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div v-for="(item, key) in items" :key="'id-'+key" class="swiper-slide">
                    <slot v-bind:item="item"></slot>
                </div>
            </div>
        </div>
        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev">
            <icon-nav-left/>
        </div>
        <div class="swiper-button-next">
            <icon-nav-right/>
        </div>
    </div>
</template>

<style scoped>
.swiper-slide {
    width: 300px;
}
</style>