<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-nav-left">
        <svg :width="w" :height="h" viewBox="0 0 32 32">
            <path
                d="M8.9388 14.9388L18.9388 4.9388C19.2206 4.65701 19.6028 4.4987 20.0013 4.4987C20.3998 4.4987 20.782 4.65701 21.0638 4.9388C21.3456 5.2206 21.5039 5.60279 21.5039 6.0013C21.5039 6.39982 21.3456 6.78201 21.0638 7.0638L12.1251 16.0001L21.0613 24.9388C21.2008 25.0783 21.3115 25.244 21.387 25.4263C21.4625 25.6086 21.5014 25.804 21.5014 26.0013C21.5014 26.1986 21.4625 26.394 21.387 26.5763C21.3115 26.7586 21.2008 26.9243 21.0613 27.0638C20.9218 27.2033 20.7561 27.314 20.5738 27.3895C20.3915 27.465 20.1961 27.5039 19.9988 27.5039C19.8015 27.5039 19.6061 27.465 19.4238 27.3895C19.2415 27.314 19.0758 27.2033 18.9363 27.0638L8.93631 17.0638C8.79663 16.9243 8.68587 16.7586 8.61037 16.5761C8.53488 16.3937 8.49614 16.1982 8.49637 16.0008C8.4966 15.8034 8.5358 15.6079 8.61173 15.4257C8.68765 15.2435 8.7988 15.078 8.9388 14.9388Z"
            />
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-nav-left",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>