<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-play">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24"
             shape-rendering="geometricPrecision">
            <circle r="11.5" fill="none" stroke="#fff" cx="12" cy="12"/>
            <path d="M9.353 16.952V7.048L16.907 12l-7.554 4.952z"/>
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-play-circle",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>
